<template>
  <v-sheet class="customer">
    <v-row>
      <v-col md="3" class="">
        <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label me-0"
                  style="font-weight: 600; font-size: 18px"
                >
                  <v-icon
                    large
                    class="custom-icon-label me-2"
                    style="background-color: #90caf9; color: white"
                    >person</v-icon
                  >All</v-list-item-title
                >
              </v-list-item-content>
              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600"
                    >145</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="">
        <v-card class="mx-auto" style="border: 0.5px solid #ef9a9a">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <v-icon
                    large
                    class="custom-icon-label me-2"
                    style="background-color: #ef9a9a; color: white"
                    >mdi-calendar</v-icon
                  >This Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#F44336" style="font-size: 18px; color: white; font-weight: 600"
                    >5</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="">
        <v-card class="mx-auto" style="border: 0.5px solid #e6ee9c">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <v-icon
                    large
                    class="custom-icon-label me-2"
                    style="background-color: #e6ee9c; color: white"
                    >mdi-calendar</v-icon
                  >Last Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#4CAF4E" style="font-size: 18px; color: white; font-weight: 600"
                    >20</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="3" class="">
        <v-card class="mx-auto" style="border: 0.5px solid #81c784">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <v-icon
                    large
                    class="custom-icon-label me-2"
                    style="background-color: #81c784; color: white"
                    >mdi-calendar</v-icon
                  >
                  Last 4 Month Ave..
                </v-list-item-title>
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading"
                  ><v-chip color="#1B5E20" style="font-size: 18px; color: white; font-weight: 600"
                    >45</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text class="px-0 my-auto py-0">
      <v-row>
        <v-col md="3" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter By User</label
          >
          <SelectInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="['All', 'ACTIVE', 'IN-ACTIVE']"
            :subTitle="value"
            id="salutation"
            placeholder="Select"
          ></SelectInput>
        </v-col>
        <v-col md="2" class="py-0">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600"
            >Filter By Display Name</label
          >
          <SelectInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            :items="['January', 'February', 'March', 'April']"
            id="segment"
            item-text="name"
            item-value="id"
            placeholder="Select Month"
          ></SelectInput>
        </v-col>
        <v-col class="mt-5">
          <v-row>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text me-1"
                    >Filter</v-btn
                  >
                </template>
                <span>Filter</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text me-1"
                    >Reset Filter</v-btn
                  >
                </template>
                <span>Reset Filter</span>
              </v-tooltip>
            </v-flex>
          </v-row>
        </v-col>
        <v-col class="mt-5 justify-content-end">
          <v-flex class="d-flex justify-content-end">
            <v-btn
              :disabled="pageLoading"
              color="blue darken-4"
              depressed
              tile
              class="ml-2 white--text me-2"
              :to="{ name: 'users-add', query: { t: new Date().getTime() } }"
            >
              <v-icon>mdi-plus</v-icon> New User
            </v-btn>
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in suppliertableHeading"
                  :key="item.title + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <v-checkbox
                      :ripple="false"
                      hide-details
                      color="#0d47a1"
                      v-model="item.status"
                      class="m-0"
                    ></v-checkbox>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-icon>drag_indicator</v-icon>
                  </div>
                  <v-divider
                    v-if="index < suppliertableHeading.length - 1"
                    class="mt-1 mb-0"
                    style="width: 100%"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <v-simple-table fixed-header class="bt-table table_height_supplier">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="heading in suppliertableHeading"
              :key="`_head_${heading.title}`"
              style="background-color: #f5f5f5"
            >
              <div v-if="heading.status">
                <span v-if="heading.title === '#'">
                  <v-checkbox
                    hide-details
                    color="#0d47a1"
                    class="m-0"
                    v-model="supplier_selectedAll"
                  ></v-checkbox>
                </span>
                <span v-else> {{ heading.title }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="customers.length">
            <tr
              v-for="(row, index) in salesSummaryDate"
              :key="`$_${index + index * 5}_supplier_` + index"
              :class="index % 2 === 0 && 'blue lighten-5'"
              class="product-listing-tr"
            >
              <td
                v-for="(th, index) in suppliertableHeading"
                :key="'key_' + index"
                class="cursor-pointer"
              >
                <v-chip
                  color="#4CAF50"
                  v-if="getColValue(th.title) === 'date' && th.status"
                  style="color: white; font-weight: 600"
                >
                  {{ row[getColValue(th.title)]?.value }}
                </v-chip>
                <div v-else-if="getColValue(th.title) === '#' && th.status">
                  <v-checkbox v-model="row.checkbox_status" color="#0D47A1"></v-checkbox>
                </div>
                <div v-else-if="getColValue(th.title) === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        class="mx-2"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click.stop.prevent="routeToUpdate(row.id)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click.stop.prevent="deleteConfirm(row)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>

                <div
                  v-else-if="getColValue(th.title) === 'image' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <ImageTemplate
                    :src="row[getColValue(th.title)]?.link"
                    :maxWidth="50"
                    :maxHeight="50"
                    :aspectRatio="1"
                    :contain="false"
                    customClass="mr-2"
                    circle
                  ></ImageTemplate>
                </div>
                <div
                  v-if="getColValue(th.title) === 'user' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <p class="my-auto py-2">
                    <v-chip class="py-0" small outlined color="primary" label>
                      <ValueTemplate
                        :value="row[getColValue(th.title)]?.value[0].status_no"
                        title="Code"
                      ></ValueTemplate>
                    </v-chip>
                  </p>
                  <p>
                    <v-chip color="green" outlined small style="font-weight: 600" label>
                      <ValueTemplate
                        :value="row[getColValue(th.title)]?.value[0].status_active"
                        title="Status"
                      ></ValueTemplate>
                    </v-chip>
                  </p>
                </div>

                <div
                  v-if="getColValue(th.title) === 'display_name' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <ValueTemplate
                    :value="row[getColValue(th.title)]?.value[0].company_user"
                    title="Status"
                  ></ValueTemplate>
                </div>
                <div
                  v-else-if="getColValue(th.title) === 'email' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <ValueTemplate
                    :value="row[getColValue(th.title)]?.value[0].company_email"
                    title="Status"
                  ></ValueTemplate>
                </div>

                <div
                  v-else-if="getColValue(th.title) === 'phone_number' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <template v-if="row[getColValue(th.title)]?.value">
                    <div
                      class="py-1"
                      v-for="(category, index) in row[getColValue(th.title)]?.value.split(',')"
                      :key="`_category_${index}`"
                    >
                      {{ category.trim() }}
                    </div>
                  </template>
                </div>

                <div
                  v-else-if="getColValue(th.title) === 'created_at' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <p class="my-auto py-2">
                    <v-chip class="py-0" small outlined color="primary" label>
                      <ValueTemplate
                        :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                        title="Admin"
                      ></ValueTemplate>
                    </v-chip>
                  </p>
                  <p>
                    <v-chip small label style="font-weight: 600">
                      <ValueTemplate
                        :value="row[getColValue(th.title)]?.value[0].created_date"
                        title="Created Date"
                      ></ValueTemplate>
                    </v-chip>
                  </p>
                </div>
                <div
                  v-else-if="getColValue(th.title) === 'role' && th.status"
                  link
                  v-on:click="routeToDetailSupplier(row)"
                >
                  <template
                    v-if="
                      row[getColValue(th.title)]?.value[0].createdBy_admin &&
                      row[getColValue(th.title)]?.value[0].created_date
                    "
                  >
                    <p class="my-auto py-2">
                      <v-chip class="py-0" small outlined color="primary" label>
                        <ValueTemplate
                          :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                          title="Created by admin"
                        ></ValueTemplate>
                      </v-chip>
                    </p>
                    <p>
                      <v-chip small label style="font-weight: 600">
                        <ValueTemplate
                          :value="row[getColValue(th.title)]?.value[0].created_date"
                          title="Created Date"
                        ></ValueTemplate>
                      </v-chip>
                    </p>
                  </template>
                  <template v-else>
                    <p style="font-size: 16px; font-weight: 600">no modified</p>
                  </template>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no customer at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="`_$idr_key_${idr}`">
            <td v-for="idk in 9" :key="`_$idk_key_${idk}`">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="customers.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Desctiption</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img
                width="70"
                height="70"
                src="https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <DeleteTemplate
      type="products"
      :delete-text="deleteText"
      delete-note="All leave will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getCustomers()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import ValueTemplate from "@/view/components/ValueTemplate";
import ImageTemplate from "@/view/components/Image";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";

export default {
  name: "supplier-listing",
  title: "Listing Supplier",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      supplier_selectedAll: false,
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      customers: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      suppliertableHeading: [
        { title: "#", status: true },
        { title: "ACTION", status: true },
        { title: "IMAGE", status: true },
        { title: "USER", status: true },
        { title: "DISPLAY NAME", status: true },
        { title: "EMAIL", status: true },
        { title: "PHONE NUMBER", status: true },
        { title: "ROLE", status: true },
        { title: "CREATED AT", status: true },
      ],
      salesSummaryDate: [
        {
          id: 1,
          checkbox_status: false,
          code: "GHI789",
          select: { value: "", key: "SELECT" },
          action: { value: "", key: "ACTION" },
          image: {
            link: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
          },
          user: {
            value: [
              {
                status_no: "S0018",
                status_active: "ACTIVE",
              },
            ],
            key: "USER",
          },
          display_name: {
            value: [
              {
                company_user: "Bryan Manager",
                testing_enterprises: "tesing enterprises",
                company_email: "bthrust@gmail.com",
                company_number: "",
                display_name: "bthrust testing display",
              },
            ],
            key: "DISPLAY NAME",
          },
          email: {
            value: [
              {
                _primary: "PRIMARY",
                company_title: "bthrush testing display",
                company_email: "bthrust@gmail.com",
                company_number: "+65-75757575",
                oneMore_details: "+1 more",
              },
            ],
            key: "EMAIL",
          },
          phone_number: { value: "+60 87453874", key: "PHONE NUMBER" },
          created_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "29/08/2023 05:54PM",
                updated_date: "",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
              },
            ],
            key: "CREATED AT",
          },
          role: {
            value: [
              {
                createdBy_admin: "",
                created_date: "",
                updated_date: "21/02/2023 03:14PM",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
              },
            ],
            key: "ROLE",
          },
        },
        {
          id: 2,
          checkbox_status: false,
          code: "MNG272",
          select: { value: "", key: "SELECT" },
          action: { value: "", key: "ACTION" },
          image: {
            link: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHVzZXJzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
          },
          user: {
            value: [
              {
                status_no: "S0017",
                status_active: "INACTIVE",
              },
            ],
            key: "USER",
          },
          display_name: {
            value: [
              {
                company_user: "John Super Admin",
                testing_enterprises: "Tri-Start Industries Pte Ltd",
                company_email: "sup@bt.com",
                company_number: "+65-96535164",
                display_name: "bthrust testing display",
              },
            ],
            key: "DISPLAY NAME",
          },
          email: {
            value: [
              {
                _primary: "PRIMARY",
                company_title: "bthrush testing display",
                company_email: "bthrust@gmail.com",
                company_number: "+65-98505334",
                oneMore_details: "+1 more",
              },
            ],
            key: "EMAIL",
          },
          phone_number: { value: "+61 873457345", key: "PHONE NUMBER" },
          created_at: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "ADMIN",
                updated_date: "21/02/2023 03:14PM",
                updated_by: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
                created_time: "",
              },
            ],
            key: "CREATED AT",
          },
          role: {
            value: [
              {
                createdBy_admin: "SUPER ADMIN",
                created_date: "MANAGER",
                updated_date: "21/02/2023 03:14PM",
                created_by: "29/08/2023 05:54PM",
                updated_by: "21/02/2023 03:14PM",
              },
            ],
            key: "ROLE",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    supplier_selectedAll(val) {
      this.salesSummaryDate.map((item) => (item.checkbox_status = val));
    },
    suppliertableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getSummary();
    },
    listingSearch() {
      this.getSummary();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getSummary();
      }
    },
  },
  methods: {
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.suppliertableHeading[this.dragStartIndex];
      this.suppliertableHeading.splice(this.dragStartIndex, 1);
      this.suppliertableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSummary();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "create-supplier",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    // routeToDetailSupplier(id) {}
    routeToDetailSupplier(row) {
      this.$router.push({
        name: "user-detail",
        params: { id: row.id, row: row },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ code, id }) {
      // const delete_row = this.salesSummaryDate[0].code;
      // console.log(delete_row, "delete_row");
      this.deleteText = code;
      this.deleteEndpoint = `leave/${id}`;
      this.deleteDialog = true;
    },
    async getSummary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_CUSTOMERS(form);
        this.customers = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSummary();
    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Supplier");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    ImageTemplate,
    ValueTemplate,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    SearchTemplate,
    SelectInput,
    Dialog,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.v-image.v-responsive.white.lighten-2.theme--light.border-light-grey.max-width-100px {
  height: 58px;
  width: 70px;
}

.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_supplier .v-data-table__wrapper {
  height: calc(100vh - 270px);
}

::-webkit-scrollbar {
  height: 10px;
}

.product-listing-tr:nth-child(even):hover {
  background: #fff !important;
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}

.product-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}
</style>
